/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/apis/user/user.service';
import { ProgressBarService } from 'src/app/services/useful/loading/progress-bar/progress-bar.service';
import { ToastService } from 'src/app/services/useful/toast/toast.service';

@Component({
  selector: 'app-modal-endereco',
  templateUrl: './modal-endereco.page.html',
  styleUrls: ['./modal-endereco.page.scss'],
})
export class ModalEnderecoPage {
  @Input('endereco') endereco;
  public ufs = [];
  public cidades = [];

  constructor(
    public modalController: ModalController,
    private userService: UserService,
    private progressBar: ProgressBarService,
    private toast: ToastService
  ) {}

  ionViewWillEnter() {
    this.GetUFs();
    console.log(this.endereco);
  }

  GoClose() {
    this.modalController.dismiss({
      // dismissed: ev
    });
  }

  GetUFs() {
    this.userService.GetUF().subscribe(
      (res) => {
        setTimeout(() => {
          if (res.sucesso === true) {
            this.ufs = res.dados.lista;
          } else {
          }
        }, 100);
      },
      (error) => {}
    );
  }

  ChangeUF(uf) {
    this.GetUCidades(uf.detail.value);
  }

  GetUCidades(id) {
    this.userService.GetCidade(id).subscribe(
      (res) => {
        setTimeout(() => {
          if (res.sucesso === true) {
            this.cidades = res.dados.lista;
          } else {
          }
        }, 100);
      },
      (error) => {}
    );
  }

  updateEndereco(form) {
    this.progressBar.startProgressBar();
    this.userService.EditEndereco(form.value, this.endereco).subscribe(
      (res) => {
        setTimeout(() => {
          if (res.sucesso === true) {
            this.progressBar.stopProgressBar();
            // this.router.navigate(['user', 'your-data']);
            this.modalController.dismiss({
              dismissed: 'atualizado',
            });
          } else {
            this.progressBar.stopProgressBar();
            this.modalController.dismiss({
              dismissed: 'erro',
            });
          }
        }, 100);
      },
      (error) => {
        this.progressBar.stopProgressBar();
        this.modalController.dismiss({
          dismissed: 'erro',
        });
      }
    );
  }
}
